import "@/styles/globals.css";
import { Config } from "@/utils/Config";
import "@purpledeerstudio/logistics-components/dist/style.css";
import type { AppProps } from "next/app";
import { useEffect } from "react";
import smartlook from "smartlook-client";

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    // Init smarlook only on client-side
    if (document && !smartlook.initialized() && Config.IS_PROD) {
      smartlook.init("28f9c11cab348c61428ceb9398d389c1ed3ccca1", {
        region: "eu",
      });
    }
  }, []);

  return <Component {...pageProps} />;
}
